import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type FileStorage = {
  id: number;
  date: string;
  name: string;
  module: string;
  reference_no: string;
  upload_type: string;
  file_name: string;
  remark:string
  link: string;
  uploaded_by_id: number;
  version: number;
  user: User;
};

export type FileStorageStateTypes = {
  fetchOne: ApiCallState<FileStorage | {}>;
  fetchVersion: ApiCallState<number | null>;
  fetchRoad:ApiCallState<FileStorage[]>;
  fetchOther:ApiCallState<FileStorage[]>
  fetchAllStructural: ApiCallState<FileStorage[]>;
  fetchAllArchitecture: ApiCallState<FileStorage[]>;
  fetchAllPlumbing: ApiCallState<FileStorage[]>;
  fetchAllMechanical: ApiCallState<FileStorage[]>;
  fetchAllElectrical: ApiCallState<FileStorage[]>;
  fetchAllFireFighting: ApiCallState<FileStorage[]>;
  fetchAllSpecialSystem: ApiCallState<FileStorage[]>;
  fetchAllSanitary: ApiCallState<FileStorage[]>;
};

export const FileStorageActionTypes = {
  FETCH_FILE_STORAGE_VERSION: "FETCH_FILE_STORAGE_VERSION",
  FETCH_FILE_STORAGE_VERSION_RESET: "FETCH_FILE_STORAGE_VERSION_RESET",
  FETCH_FILE_STORAGE_VERSION_FAILURE: "FETCH_FILE_STORAGE_VERSION_FAILURE",
  FETCH_FILE_STORAGE_VERSION_SUCCESS: "FETCH_FILE_STORAGE_VERSION_SUCCESS",

  FETCH_ONE_FILE_STORAGE: "FETCH_ONE_FILE_STORAGE",
  FETCH_ONE_FILE_STORAGE_RESET: "FETCH_ONE_FILE_STORAGE_RESET",
  FETCH_ONE_FILE_STORAGE_FAILURE: "FETCH_ONE_FILE_STORAGE_FAILURE",
  FETCH_ONE_FILE_STORAGE_SUCCESS: "FETCH_ONE_FILE_STORAGE_SUCCESS",

  FETCH_ALL_OTHER_FILE_STORAGE: "FETCH_ALL_OTHER_FILE_STORAGE",
  FETCH_ALL_OTHER_FILE_STORAGE_RESET:
    "FETCH_ALL_OTHER_FILE_STORAGE_RESET",
  FETCH_ALL_OTHER_FILE_STORAGE_FAILURE:
    "FETCH_ALL_OTHER_FILE_STORAGE_FAILURE",
  FETCH_ALL_OTHER_FILE_STORAGE_SUCCESS:
    "FETCH_ALL_OTHER_FILE_STORAGE_SUCCESS",

    FETCH_ALL_ROAD_FILE_STORAGE: "FETCH_ALL_ROAD_FILE_STORAGE",
    FETCH_ALL_ROAD_FILE_STORAGE_RESET:
      "FETCH_ALL_ROAD_FILE_STORAGE_RESET",
    FETCH_ALL_ROAD_FILE_STORAGE_FAILURE:
      "FETCH_ALL_ROAD_FILE_STORAGE_FAILURE",
    FETCH_ALL_ROAD_FILE_STORAGE_SUCCESS:
      "FETCH_ALL_ROAD_FILE_STORAGE_SUCCESS",


    FETCH_ALL_STRUCTURAL_FILE_STORAGE: "FETCH_ALL_STRUCTURAL_FILE_STORAGE",
    FETCH_ALL_STRUCTURAL_FILE_STORAGE_RESET:
      "FETCH_ALL_STRUCTURAL_FILE_STORAGE_RESET",
    FETCH_ALL_STRUCTURAL_FILE_STORAGE_FAILURE:
      "FETCH_ALL_STRUCTURAL_FILE_STORAGE_FAILURE",
    FETCH_ALL_STRUCTURAL_FILE_STORAGE_SUCCESS:
      "FETCH_ALL_STRUCTURAL_FILE_STORAGE_SUCCESS",

  FETCH_ALL_ARCHITECTURE_FILE_STORAGE: "FETCH_ALL_ARCHITECTURE_FILE_STORAGE",
  FETCH_ALL_ARCHITECTURE_FILE_STORAGE_RESET:
    "FETCH_ALL_ARCHITECTURE_FILE_STORAGE_RESET",
  FETCH_ALL_ARCHITECTURE_FILE_STORAGE_FAILURE:
    "FETCH_ALL_ARCHITECTURE_FILE_STORAGE_FAILURE",
  FETCH_ALL_ARCHITECTURE_FILE_STORAGE_SUCCESS:
    "FETCH_ALL_ARCHITECTURE_FILE_STORAGE_SUCCESS",

  FETCH_ALL_PLUMBING_FILE_STORAGE: "FETCH_ALL_PLUMBING_FILE_STORAGE",
  FETCH_ALL_PLUMBING_FILE_STORAGE_RESET:
    "FETCH_ALL_PLUMBING_FILE_STORAGE_RESET",
  FETCH_ALL_PLUMBING_FILE_STORAGE_FAILURE:
    "FETCH_ALL_PLUMBING_FILE_STORAGE_FAILURE",
  FETCH_ALL_PLUMBING_FILE_STORAGE_SUCCESS:
    "FETCH_ALL_PLUMBING_FILE_STORAGE_SUCCESS",

  FETCH_ALL_MECHANICAL_FILE_STORAGE: "FETCH_ALL_MECHANICAL_FILE_STORAGE",
  FETCH_ALL_MECHANICAL_FILE_STORAGE_RESET:
    "FETCH_ALL_MECHANICAL_FILE_STORAGE_RESET",
  FETCH_ALL_MECHANICAL_FILE_STORAGE_FAILURE:
    "FETCH_ALL_MECHANICAL_FILE_STORAGE_FAILURE",
  FETCH_ALL_MECHANICAL_FILE_STORAGE_SUCCESS:
    "FETCH_ALL_MECHANICAL_FILE_STORAGE_SUCCESS",

  FETCH_ALL_ELECTRICAL_FILE_STORAGE: "FETCH_ALL_ELECTRICAL_FILE_STORAGE",
  FETCH_ALL_ELECTRICAL_FILE_STORAGE_RESET:
    "FETCH_ALL_ELECTRICAL_FILE_STORAGE_RESET",
  FETCH_ALL_ELECTRICAL_FILE_STORAGE_FAILURE:
    "FETCH_ALL_ELECTRICAL_FILE_STORAGE_FAILURE",
  FETCH_ALL_ELECTRICAL_FILE_STORAGE_SUCCESS:
    "FETCH_ALL_ELECTRICAL_FILE_STORAGE_SUCCESS",

  FETCH_ALL_FIRE_FIGHTING_FILE_STORAGE: "FETCH_ALL_FIRE_FIGHTING_FILE_STORAGE",
  FETCH_ALL_FIRE_FIGHTING_FILE_STORAGE_RESET:
    "FETCH_ALL_FIRE_FIGHTING_FILE_STORAGE_RESET",
  FETCH_ALL_FIRE_FIGHTING_FILE_STORAGE_FAILURE:
    "FETCH_ALL_FIRE_FIGHTING_FILE_STORAGE_FAILURE",
  FETCH_ALL_FIRE_FIGHTING_FILE_STORAGE_SUCCESS:
    "FETCH_ALL_FIRE_FIGHTING_FILE_STORAGE_SUCCESS",

  FETCH_ALL_SPECIAL_SYSTEM_FILE_STORAGE:
    "FETCH_ALL_SPECIAL_SYSTEM_FILE_STORAGE",
  FETCH_ALL_SPECIAL_SYSTEM_FILE_STORAGE_RESET:
    "FETCH_ALL_SPECIAL_SYSTEM_FILE_STORAGE_RESET",
  FETCH_ALL_SPECIAL_SYSTEM_FILE_STORAGE_FAILURE:
    "FETCH_ALL_SPECIAL_SYSTEM_FILE_STORAGE_FAILURE",
  FETCH_ALL_SPECIAL_SYSTEM_FILE_STORAGE_SUCCESS:
    "FETCH_ALL_SPECIAL_SYSTEM_FILE_STORAGE_SUCCESS",

  FETCH_ALL_SANITARY_FILE_STORAGE: "FETCH_ALL_SANITARY_FILE_STORAGE",
  FETCH_ALL_SANITARY_FILE_STORAGE_RESET:
    "FETCH_ALL_SANITARY_FILE_STORAGE_RESET",
  FETCH_ALL_SANITARY_FILE_STORAGE_FAILURE:
    "FETCH_ALL_SANITARY_FILE_STORAGE_FAILURE",
  FETCH_ALL_SANITARY_FILE_STORAGE_SUCCESS:
    "FETCH_ALL_SANITARY_FILE_STORAGE_SUCCESS",
};
