import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import LockOutlined from "@ant-design/icons/LockOutlined";
import MoreOutlined from "@ant-design/icons/MoreOutlined";
import UnlockOutlined from "@ant-design/icons/UnlockOutlined";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Popover,
  Select,
  Table,
  Tag,
} from "antd";
import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  COMMUNICATION_TYPE,
  COMMUNICATION_TYPE_FILTER,
  CommunicationPropType,
  DELETE,
  DELETE_STATUS,
  POST_STATUS,
} from "./utils/Communication.util";
import ReloadButtonComponent from "../common/ReloadButton/ReloadButton.component";
import { fetchAllCommunication } from "../../redux/Communication/Communication.action";
import { fetchAllProjects } from "../../redux/Project/Project.action";
import { OpenNotification } from "../common/Notification/Notification.component";
import {
  ErrorHandler,
  getUserData,
  searchProp,
  zeroPad,
} from "../../utilities/utilities";
import { NotificationType } from "../../constants/Constants";
import ShareComponent from "../common/Share/Share.component";
import AddCommunicationComponent from "./components/Add/AddCommunication.component";
import ViewCommunicationComponent from "./components/View/ViewCommunication.component";
import { Communication } from "../../redux/Communication/Communication.type";
import moment from "moment";
import ViewAttachmentComponent from "./components/View/ViewAttachment.component";
import { fetchAllUser } from "../../redux/User/User.action";
import isNil from "lodash/isNil";
import RemarkComponent from "./components/Remark/Remark.component";
import { useParams } from "react-router-dom";

const CommunicationComponent: FC<CommunicationPropType> = ({
  fetchCommunications,
  communications,
  fetchProjects,
  projects,
  fetchUsers,
}) => {
  const { id } = useParams();
  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [project_id, setProjectId] = useState<any>();
  const [search, setSearch] = useState("");
  const [range, setRange] = useState<any>([]);
  const [show_all, setShowAll] = useState(id ? false : true);
  useEffect(() => {
    fetchCommunications({ project_id });
  }, [fetchCommunications, project_id]);

  useEffect(() => {
    fetchProjects();
    fetchUsers();
  }, [fetchProjects, fetchUsers]);

  useEffect(() => {
    if (projects.payload.length > 0) {
      if (id) setProjectId(Number(id.split("-")[0]));
      else setProjectId(projects.payload[0].id);
    }
  }, [projects, id]);

  const onDelete = (id: any) => {
    DELETE(id)
      .then(() => {
        fetchCommunications({ project_id });

        setLoading(false);
        OpenNotification(NotificationType.SUCCESS, "Communication Removed", "");
      })
      .catch((error) => {
        setLoading(false);
        ErrorHandler(error).map((e: any) =>
          OpenNotification(
            NotificationType.ERROR,
            "Failed to Remove Communication ",
            e.message
          )
        );
      });
  };

  const onStatusRemove = (id: number) => {
    setLoading(true);
    DELETE_STATUS(id)
      .then(() => {
        setLoading(false);
        fetchCommunications({ project_id });
        OpenNotification(NotificationType.SUCCESS, "Assigned User Removed", "");
      })
      .catch((error) => {
        setLoading(false);
        ErrorHandler(error).map((e: any) =>
          OpenNotification(
            NotificationType.ERROR,
            "Failed to Remove Assigned User",
            e.message
          )
        );
      });
  };

  const getStatus = (communication: Communication) => {
    if (communication.reply_date) {
      if (communication.response_to)
        return (
          <Tag color="green" style={{ width: "87px", textAlign: "center" }}>
            Done
          </Tag>
        );
      else if (moment().isAfter(moment(communication.reply_date), "date"))
        return (
          <Tag color="red" style={{ width: "87px", textAlign: "center" }}>
            Outstanding
          </Tag>
        );
      else
        return (
          <Tag color="yellow" style={{ width: "87px", textAlign: "center" }}>
            Outstanding
          </Tag>
        );
    } else
      return (
        <Tag color="green" style={{ width: "87px", textAlign: "center" }}>
          Done
        </Tag>
      );
  };

  const onCreateStatus = (data: any) => {
    setLoading(true);
    POST_STATUS(data)
      .then(() => {
        setLoading(false);
        fetchCommunications({ project_id });
        OpenNotification(NotificationType.SUCCESS, "User Assigned", "");
      })
      .catch((error) => {
        setLoading(false);
        ErrorHandler(error).map((e: any) =>
          OpenNotification(
            NotificationType.ERROR,
            "Failed to Assign User",
            e.message
          )
        );
      });
  };

  return (
    <Card>
      <div className="row">
        <div className="col-md-3">
          <Form.Item>
            <Select
              {...searchProp}
              onChange={(e) => setProjectId(e)}
              value={project_id}
            >
              {projects.payload.map((e) => (
                <Select.Option value={e.id} key={e.id}>
                  {e.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="col-md-3">
          <Form.Item>
            <Input.Search
              placeholder="Ref-No Subject "
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Form.Item>
        </div>
        <div className="col-md-4">
          <Form.Item>
            <DatePicker.RangePicker
              value={range}
              onChange={(e) => setRange(e)}
            />
          </Form.Item>
        </div>
        <div className="col-md-2">
          {id ? (
            <Checkbox
              checked={show_all}
              onChange={(e) => setShowAll(e.target.checked)}
            >
              Show All
            </Checkbox>
          ) : null}
          <ReloadButtonComponent
            onClick={() => fetchCommunications({ project_id })}
          />{" "}
          <AddCommunicationComponent
            projectAction={[project_id, setProjectId]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <Form.Item>
            <Select
              placeholder="Type"
              onChange={(e) => setType(e)}
              value={type}
              allowClear
              options={COMMUNICATION_TYPE}
              showSearch
              filterOption={(input, option) =>
                option?.value
                  ? option!.value.toUpperCase().indexOf(input.toUpperCase()) !==
                    -1
                  : false
              }
            />
          </Form.Item>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-12">
          <Table
            size="small"
            tableLayout="fixed"
            loading={communications.isPending}
            dataSource={communications.payload
              ?.filter(
                (e) =>
                  (e.subject
                    ?.toLocaleLowerCase()
                    .search(search?.toLocaleLowerCase()) !== -1 ||
                    e.reference
                      ?.toLocaleLowerCase()
                      .search(search?.toLocaleLowerCase()) !== -1) &&
                  (id
                    ? e?.id === Number(id.split("-")[1]) || show_all
                    : true) &&
                  (!isNil(range)
                    ? range?.length === 0 ||
                      moment(e.date).isBetween(range[0], range[1], "D", "[]")
                    : true) &&
                  (type === "" || isNil(type) ? true : e.type === type)
              )
              .map((e, index) => ({
                ...e,
                key: index + 1,
              }))}
            columns={[
              {
                title: "No",
                key: "no",
                dataIndex: "key",
                fixed: "left",
                width: "3%",
              },
              {
                title: "Date",
                key: "date",
                dataIndex: "date",

                width: "8%",
                defaultSortOrder: "ascend",
                sorter: (a, b) => (moment(a.date).isBefore(b.date) ? 1 : -1),
              },
              {
                title: "Type",
                key: "type",
                dataIndex: "type",
                filters: COMMUNICATION_TYPE_FILTER,
                onFilter: (value, record) =>
                  record.type?.includes(value as string),
              },
              {
                title: "Reference",
                key: "reference",
                dataIndex: "reference",
              },
              {
                title: "Subject",
                key: "subject",
                dataIndex: "subject",
              },
              {
                title: "From",
                key: "from",
                dataIndex: "from",
              },
              {
                title: "To",
                key: "to",
                dataIndex: "to",
              },

              {
                title: "Remark",
                key: "remark",
                width: "6%",
                align: "center",
                render: (value, record) => (
                  <RemarkComponent
                    record={record}
                    fetchCommunications={() =>
                      fetchCommunications({ project_id })
                    }
                  />
                ),
              },
              {
                title: "Sensitive",
                dataIndex: "sensitive",
                align: "center",
                render: (value) =>
                  value ? (
                    <LockOutlined style={{ color: "#b91c1c" }} />
                  ) : (
                    <UnlockOutlined style={{ color: "#047857" }} />
                  ),
              },

              {
                title: "Attachment",
                key: "attachment",
                align: "center",
                render: (value, record) =>
                  (
                    record.sensitive
                      ? record.share_to.includes(getUserData().id) ||
                        record.user_id === getUserData().id ||
                        getUserData().is_super_user
                      : true
                  ) ? (
                    <ViewAttachmentComponent communication={record} />
                  ) : null,
              },

              {
                title: "Status",
                key: "status",
                width: "8%",
                render: (value, record) => getStatus(record),
                filters: [
                  {
                    text: "Outstanding",
                    value: "outstanding",
                  },
                  {
                    text: "Done",
                    value: "done",
                  },
                ],
                onFilter: (value, record) => {
                  if (value === "done") {
                    return !record.reply_date ? true : false;
                  } else if (value === "outstanding") {
                    return record.reply_date && !record.response_to
                      ? true
                      : false;
                  } else return true;
                },
              },
              {
                title: "Reply By",
                key: "share",
                width: "5%",
                render: (value, record) =>
                  record.reply_date
                    ? moment(record.reply_date).format("DD-MM-YYYY")
                    : "-",
              },
              {
                title: "Action",
                width: "5%",
                render: (value, record) => (
                  <Popover
                    placement="rightTop"
                    overlayClassName="action-popover"
                    trigger="focus"
                    content={
                      <div className="d-flex flex-column">
                        <ViewCommunicationComponent communication={record} />
                        {getUserData().is_super_user ? (
                          <Popconfirm
                            title="Are you sure to delete this Item?"
                            onConfirm={() => onDelete(record.id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              danger
                              loading={loading}
                              type="text"
                              icon={<DeleteOutlined />}
                            >
                              Delete
                            </Button>
                          </Popconfirm>
                        ) : null}
                      </div>
                    }
                  >
                    <Button
                      icon={<MoreOutlined />}
                      className="btn-outline-secondary border-0"
                    ></Button>
                  </Popover>
                ),
              },
            ]}
          />
        </div>
      </div>
    </Card>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({
  communications: state.communication.fetchAll,
  projects: state.project.fetchAll,
});

/**
 * Map Dispatch to Props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => ({
  fetchCommunications: (action: any) => dispatch(fetchAllCommunication(action)),
  fetchProjects: (action: any) => dispatch(fetchAllProjects(action)),
  fetchUsers: (action: any) => dispatch(fetchAllUser(action)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationComponent);
