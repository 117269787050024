import axios from "axios";
import { API_BASE_URI } from "../ApiCall";
import { put, takeLatest } from "redux-saga/effects";
import { ApplicationActionTypes } from "./Application.type";

export function* fetchAllApplications(action: any): any {
  try {
    console.log(action.payload);
    const response = yield axios.get(
      `${API_BASE_URI}/application/${action.payload.vacancyId}`,

    );
    yield put({
      type: ApplicationActionTypes.FETCH_ALL_APPLICATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApplicationActionTypes.FETCH_ALL_APPLICATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneApplication(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/application/${action.payload}`,
     
    );
    yield put({
      type: ApplicationActionTypes.FETCH_ONE_APPLICATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApplicationActionTypes.FETCH_ONE_APPLICATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllApplications() {
  yield takeLatest(
    ApplicationActionTypes.FETCH_ALL_APPLICATION,
    fetchAllApplications
  );
}

export function* watcherFetchOneApplication() {
  yield takeLatest(
    ApplicationActionTypes.FETCH_ONE_APPLICATION,
    fetchOneApplication
  );
}
