import { Button, Divider, Form, Modal, Statistic } from "antd";
import { FC, useState } from "react";
import { connect } from "react-redux";


import CheckOutlined from "@ant-design/icons/CheckOutlined";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import CloudDownloadOutlined from "@ant-design/icons/CloudDownloadOutlined";
import EyeOutlined from "@ant-design/icons/EyeOutlined";


import { ViewCommunicationPropType } from "../../utils/Communication.util";
import DocumentViewerComponent from "../../../common/DocumentViewer/DocumentViewer.component";
import { DownloadFile } from "../../../Document/MyDocument/index.util";
const AddCommunicationComponent: FC<ViewCommunicationPropType> = ({
  communication,
  communications,
  users,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const response = communication.response_to_id
    ? communications.payload.find(
        (e) => e.response_to_id === communication.response_to_id
      )
    : null;

  return (
    <>
      <Button
        icon={<EyeOutlined />}
        type="link"
        onClick={() => setIsModalVisible(true)}
      >
        View
      </Button>

      <Modal
        className="fixed-modal"
        centered
        width={800}
        title="Communication"
        open={isModalVisible}
        onCancel={handleOk}
        footer={[<></>]}
      >
        <div className="row">
          <div className="col-md-4">
            <Statistic
              title="Date"
              value={communication.date}
              valueStyle={{ fontSize: 16, fontFamily: "Campton-Medium" }}
            />
          </div>
          <div className="col-md-4">
            <Statistic
              title="Reply By"
              value={communication.reply_date ?? "-"}
              valueStyle={{ fontSize: 16, fontFamily: "Campton-Medium" }}
            />
          </div>
          <div className="col-md-4">
            <Statistic
              title="Type"
              value={communication.type}
              valueStyle={{ fontSize: 16, fontFamily: "Campton-Medium" }}
            />
          </div>
          <div className="col-md-4">
            <Statistic
              title="Project"
              value={communication.project.name}
              valueStyle={{ fontSize: 16, fontFamily: "Campton-Medium" }}
            />
          </div>
          <div className="col-md-4 mt-4">
            <Statistic
              title="Reference"
              value={communication.reference}
              valueStyle={{ fontSize: 16, fontFamily: "Campton-Medium" }}
            />
          </div>
          <div className="col-md-4 mt-4">
            <Statistic
              title="Subject"
              value={communication.subject}
              valueStyle={{ fontSize: 16, fontFamily: "Campton-Medium" }}
            />
          </div>
          <div className="col-md-4 mt-4">
            <Statistic
              title="Response to"
              value={
                response ? `${response.reference}-${response.subject}` : "-"
              }
              valueStyle={{ fontSize: 16, fontFamily: "Campton-Medium" }}
            />
          </div>
          <Divider />
          <div className="col-md-4">
            <Statistic
              title="From"
              value={communication.from}
              valueStyle={{ fontSize: 16, fontFamily: "Campton-Medium" }}
            />
          </div>
          <div className="col-md-4">
            <Statistic
              title="To"
              value={communication.to}
              valueStyle={{ fontSize: 16, fontFamily: "Campton-Medium" }}
            />
          </div>
          <div className="col-md-4">
            <Statistic
              title="CC"
              value={communication.cc}
              valueStyle={{ fontSize: 16, fontFamily: "Campton-Medium" }}
            />
          </div>
          <div className="col-md-4 mt-4">
            <Statistic
              title="Sensitive"
              valueRender={() =>
                communication.sensitive ? <CheckOutlined /> : <CloseOutlined />
              }
            />
          </div>
          {communication.sensitive && (
            <div className="col-md-6  mt-2">
              <Statistic
                title="Share Only To"
                valueRender={() => (
                  <>
                    {communication.share_to
                      .map(
                        (e) => users.payload.find((u) => u.id === e)?.full_name
                      )
                      .join(", ")}
                  </>
                )}
                valueStyle={{ fontSize: 16, fontFamily: "Campton-Medium" }}
              />
            </div>
          )}
          <div className="col-md-12  mt-2">
            <Form.Item
              label="File"
              rules={[{ required: true, message: "Please input File" }]}
              name="file"
            >
              {communication.documents.map((e) => (
                <span
                  style={{
                    backgroundColor: "#f7f4f4",
                    padding: "6px",
                    margin: "8px",
                    borderRadius: "13px",
                  }}
                >
                  <Button
                    style={{ fontSize: "12px" }}
                    className="px-0"
                    type="text"
                    icon={<CloudDownloadOutlined />}
                    onClick={() => DownloadFile(e)}
                  >
                    {e.name}
                  </Button>
                  <DocumentViewerComponent document={e} />
                </span>
              ))}
            </Form.Item>
          </div>
          <div className="col-md-12 mt-2">
            <Statistic
              title="Remark"
              value={communication.remark ?? "-"}
              valueStyle={{ fontSize: 16, fontFamily: "Campton-Medium" }}
            />
          </div>
          <div className="col-md-12">
            <Statistic
              title="Prepared By"
              value={communication.user.full_name}
              valueStyle={{ fontSize: 16, fontFamily: "Campton-Medium" }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({
  communications: state.communication.fetchAll,
  users: state.user.fetchAll,
});

/**
 * Map Dispatch to Props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCommunicationComponent);
