// export const BASE_URI = "http://localhost:4000";
// export const API_BASE_URI = "http://localhost:4000/api";
// export const APP_URL = "http://localhost:3000";

export const BASE_URI = "https://k2n.condigital.cloud:3000";
export const API_BASE_URI = "https://k2n.condigital.cloud:3000/api";
export const APP_URL = "https://k2n.condigital.cloud";

// export const BASE_URI = "https://ovid.condigital.cloud:3000";
// export const API_BASE_URI = "https://ovid.condigital.cloud:3000/api";
// export const APP_URL = "https://ovid.condigital.cloud";

// export const BASE_URI = "https://workspace.condigital.cloud:3000";
// export const API_BASE_URI = "https://workspace.condigital.cloud:3000/api";

// export const BASE_URI = "https://task.condigital.cloud:3000";
// export const API_BASE_URI = "https://task.condigital.cloud:3000/api";
