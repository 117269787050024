import axios from "axios";
import { Communication } from "../../../redux/Communication/Communication.type";
import { Project } from "../../../redux/Project/Project.type";
import { ApiCallState } from "../../../redux/Utils";
import { API_BASE_URI } from "../../../redux/ApiCall";
import uniq from "lodash/uniq";
import { User } from "../../../redux/User/User.type";
import { getUserData } from "../../../utilities/utilities";
import moment from "moment";

export type CommunicationPropType = {
  communications: ApiCallState<Communication[]>;
  fetchCommunications: Function;
  projects: ApiCallState<Project[]>;
  fetchProjects: Function;
  fetchUsers: Function;
};

export const parseData = (remark: Communication, user_id: number) => {
  let counter = 0;
  let user = remark.communication_users?.find((e: any) => e.id === user_id);
  if (user) {
    let last_seen = user?.["communication_user"].last_seen;
    remark.communication_remarks.forEach((e) => {
      if (!moment(last_seen).isSameOrAfter(moment(e.createdAt), "minute"))
        counter += 1;
    });
  } else {
    remark.communication_remarks.forEach((e) => {
      counter += 1;
    });
  }
  return { counter };
};

export type RemarkPropType = {
  record: Communication;
  users: ApiCallState<User[]>;
  fetchCommunications: Function;
};

export type AddCommunicationPropType = {
  communication?: Communication;
  fetchCommunications: Function;
  projectAction: [any, React.Dispatch<React.SetStateAction<any>>];
  projects: ApiCallState<Project[]>;
  communications: ApiCallState<Communication[]>;
  users: ApiCallState<User[]>;
};

export type ViewCommunicationPropType = {
  communication: Communication;
  communications: ApiCallState<Communication[]>;
  users: ApiCallState<User[]>;
};

export type ViewAttachmentPropType = {
  communication: Communication;
};

export const DELETE = (id: number) =>
  axios.delete(`${API_BASE_URI}/communication/${id}`);

export const POST = (data: any) =>
  axios.post(API_BASE_URI + "/communication", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const POST_REMARK = (data: any) =>
  axios.post(API_BASE_URI + "/communication-remark", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const PUT = (data: any) =>
  axios.post(API_BASE_URI + "/communication/seen", data);

export const PUT_STATUS = (data: any) =>
  axios.put(API_BASE_URI + "/communication-status", data);

export const DELETE_STATUS = (id: number) =>
  axios.delete(API_BASE_URI + `/communication-status/${id}`);

export const POST_STATUS = (data: any) =>
  axios.post(API_BASE_URI + "/communication-status", data);

export const getCompany = (
  project_id: number,
  projects: Project[],
  users: User[],
  show_all: boolean = false
) => {
  let project = projects.find((e) => e.id === project_id);
  if (show_all)
    return uniq(users.filter((e) => e.is_super_user).map((e) => e.company));
  else
    return project
      ? uniq(
          [
            ...project.users.map((e) => e.company),
            ...users.filter((e) => e.is_super_user).map((e) => e.company),
          ].filter((e) => e !== getUserData().company)
        )
      : [];
};

export const COMMUNICATION_TYPE = [
  {
    label: "Design",
    title: "Design",
    options: [
      {
        label: "Design",
        value: "Design",
      },
      {
        label: "Architecture",
        value: "Architecture",
      },
      {
        label: "Structure",
        value: "Structure",
      },
      {
        label: "Sanitary",
        value: "Sanitary",
      },
      {
        label: "Electrical",
        value: "Electrical",
      },
      {
        label: "Mechanical",
        value: "Mechanical",
      },
      {
        label: "Fire Fighting",
        value: "Fire Fighting",
      },
      {
        label: "Special System",
        value: "Special System",
      },
      {
        label: "Landscape",
        value: "Landscape",
      },
      {
        label: "Interior",
        value: "Interior",
      },
    ],
  },
  {
    label: "Supervision",
    title: "Supervision",
    options: [
      {
        label: "Minutes of meeting",
        value: "Minutes of meeting",
      },
      {
        label: "Sites handover",
        value: "Sites handover",
      },
      {
        label: "Site diary",
        value: "Site diary",
      },
      {
        label: "Submittal",
        value: "Submittal",
      },
      {
        label: "Inspections",
        value: "Inspections",
      },
      {
        label: "Material Test",
        value: "Material Test",
      },
      {
        label: "Material Approval",
        value: "Material Approval",
      },
      {
        label: "Daily Progress Reports",
        value: "Daily Progress Reports",
      },
      {
        label: "Weekly Progress Reports",
        value: "Weekly Progress Reports",
      },
      {
        label: "Bi-Weekly Progress Reports",
        value: "Bi-Weekly Progress Reports",
      },
      {
        label: "Monthly Progress Reports",
        value: "Monthly Progress Reports",
      },
      {
        label: "Site Book",
        value: "Site Book",
      },
      {
        label: "Site Pictures",
        value: "Site Pictures",
      },
      {
        label: "Health and Safety",
        value: "Health and Safety",
      },
    ],
  },
  {
    label: "Contract Administration",
    title: "Contract Administration",
    options: [
      {
        label: "Book of Standard and Specification",
        value: "Book of Standard and Specification",
      },
      {
        label: "Tender Document",
        value: "Tender Document",
      },
      {
        label: "Guarantee",
        value: "Guarantee",
      },
      {
        label: "Clarifications",
        value: "Clarifications",
      },
      {
        label: "Payments",
        value: "Payments",
      },
      {
        label: "Schedule",
        value: "Schedule",
      },
      {
        label: "Variation Amendments",
        value: "Variation Amendments",
      },
    ],
  },
  {
    label: "Communications",
    value: "Communications",
  },
];

export const COMMUNICATION_TYPE_FILTER = [
  {
    text: "Design",
    value: "Design",
    children: [
      {
        text: "Design",
        value: "Design",
      },
      {
        text: "Architecture",
        value: "Architecture",
      },
      {
        text: "Structure",
        value: "Structure",
      },
      {
        text: "Sanitary",
        value: "Sanitary",
      },
      {
        text: "Electrical",
        value: "Electrical",
      },
      {
        text: "Mechanical",
        value: "Mechanical",
      },
      {
        text: "Fire Fighting",
        value: "Fire Fighting",
      },
      {
        text: "Special System",
        value: "Special System",
      },
      {
        text: "Landscape",
        value: "Landscape",
      },
      {
        text: "Interior",
        value: "Interior",
      },
    ],
  },
  {
    text: "Supervision",
    value: "Supervision",
    children: [
      {
        text: "Minutes of meeting",
        value: "Minutes of meeting",
      },
      {
        text: "Sites handover",
        value: "Sites handover",
      },
      {
        text: "Site diary",
        value: "Site diary",
      },
      {
        text: "Submittal",
        value: "Submittal",
      },
      {
        text: "Inspections",
        value: "Inspections",
      },
      {
        text: "Material Test",
        value: "Material Test",
      },
      {
        text: "Material Approval",
        value: "Material Approval",
      },
      {
        text: "Daily Progress Reports",
        value: "Daily Progress Reports",
      },

      {
        text: "Weekly Progress Reports",
        value: "Weekly Progress Reports",
      },
      {
        text: "Bi-Weekly Progress Reports",
        value: "Bi-Weekly Progress Reports",
      },
      {
        text: "Monthly Progress Reports",
        value: "Monthly Progress Reports",
      },
      {
        text: "Site Book",
        value: "Site Book",
      },
      {
        text: "Site Pictures",
        value: "Site Pictures",
      },
      {
        text: "Health and Safety",
        value: "Health and Safety",
      },
    ],
  },
  {
    text: "Contract Administration",
    value: "Contract Administration",
    children: [
      {
        text: "Book of Standard and Specification",
        value: "Book of Standard and Specification",
      },
      {
        text: "Tender Document",
        value: "Tender Document",
      },
      {
        label: "Guarantee",
        value: "Guarantee",
      },
      {
        text: "Clarifications",
        value: "Clarifications",
      },
      {
        text: "Payments",
        value: "Payments",
      },
      {
        text: "Schedule",
        value: "Schedule",
      },
      {
        text: "Variation Amendments",
        value: "Variation Amendments",
      },
    ],
  },
  {
    text: "Communications",
    value: "Communications",
  },
];
