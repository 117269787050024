import { Document } from "../Document/Document.type";
import { Project } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type Communication = {
  id?: number;
  user_id: number;
  date: string;
  project_id: number;
  reference: string;
  subject: string;
  response_to_id: number;
  from: string;
  response_to?: Communication;
  to: string;
  cc: string;
  type: string;
  remark: string;
  user: User;
  documents: Document[];
  communication: Communication;
  project: Project;
  reply_date: string;
  sensitive: boolean;
  share_to: number[];
  status: {
    id: number;
    type: "View" | "Check" | "Approve";
    status: number;
    assigned_by: number;
    user_id: number;
    communication_id: number;
    assigned_by_user: User;
    assigned_to_user: User;
  }[];
  communication_users: {
    id: number;
    communication_user: {
      id: number;
      user_id: number;
      communication_id: number;
      last_seen: string;
    };
  }[];
  communication_remarks: CommunicationRemark[];
};

export type CommunicationRemark = {
  id: number | null;
  communication_id: number | null;
  date: string;
  remarked_by: number;
  remark: string;
  createdAt: string;
  document: Document;
};

export type CommunicationStateTypes = {
  fetchAll: ApiCallState<Communication[]>;
  fetchOne: ApiCallState<Communication | {}>;
};

export const CommunicationActionTypes = {
  FETCH_ALL_COMMUNICATION: "FETCH_ALL_COMMUNICATION",
  FETCH_ALL_COMMUNICATION_RESET: "FETCH_ALL_COMMUNICATION_RESET",
  FETCH_ALL_COMMUNICATION_FAILURE: "FETCH_ALL_COMMUNICATION_FAILURE",
  FETCH_ALL_COMMUNICATION_SUCCESS: "FETCH_ALL_COMMUNICATION_SUCCESS",

  FETCH_ONE_COMMUNICATION: "FETCH_ONE_COMMUNICATION",
  FETCH_ONE_COMMUNICATION_RESET: "FETCH_ONE_COMMUNICATION_RESET",
  FETCH_ONE_COMMUNICATION_FAILURE: "FETCH_ONE_COMMUNICATION_FAILURE",
  FETCH_ONE_COMMUNICATION_SUCCESS: "FETCH_ONE_COMMUNICATION_SUCCESS",
};
